<template>
  <div class="row">
    <div v-if="editable" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" title="설비 기본정보">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="editable && popupParam.noPlan === 'Y' && !updateMode && !disabled" label="설비선택" icon="add" @btnClicked="add"/>
            <c-btn v-if="editable && updateMode && !disabled" :showLoading="false" label="정비완료" icon="save" color="blue"  @btnClicked="resultComplete" />
            <c-btn
              v-if="editable && !disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="result"
              :mappingType="saveType"
              label="정비결과 저장"
              icon="save"
              @beforeAction="saveInspection"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div v-if="editable && !result.equipmentCd" class="col-12">
            <c-label-text title="설비를 선택하세요."></c-label-text>
          </div>
          <div v-if="editable && result.plantName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="사업장" :value="result.plantName"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentTypeCd" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비유형" :value="result.equipmentTypeName"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentCd" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비코드" :value="result.equipmentCd"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비명" :value="result.equipmentName"></c-label-text>
          </div>
          <div v-if="editable && result.recentMaintainDate" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="이전정비일" :value="result.recentMaintainDate"></c-label-text>
          </div>
          <div v-if="editable && result.relatedLawsName" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-label-text title="관련법규" :value="result.relatedLawsName"></c-label-text>
          </div>
          <div class="col-12">
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-card class="cardClassDetailForm" title="설비 정비결과" bgClass="">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                :disabled="disabled"
                label="정비유형"
                codeGroupCd="MDM_MAINTENANCE_KIND_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                v-model="result.equipmentCheckTypeCd"
              ></c-select>
            </div>
            <!-- <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-field
                required
                :disabled="disabled"
                label="정비예정자"
                name="maintenanceDueUserId"
                type="user" 
                v-model="result.maintenanceDueUserId">
              </c-field>
            </div>
            <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="정비예정일"
                name="maintenanceDueDate"
                v-model="result.maintenanceDueDate">
              </c-datepicker>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                :disabled="disabled"
                label="실제정비자"
                name="maintenanceUserId"
                type="user" 
                v-model="result.maintenanceUserId">
              </c-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="실제정비일"
                name="maintenanceDate"
                v-model="result.maintenanceDate">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-select
                required
                :disabled="disabled"
                :editable="editable"
                codeGroupCd="MIM_CHECK_RESULT_CD2"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="inspResultAfterMemCd"
                label="정비후 정비결과"
                v-model="result.inspResultAfterMemCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :disabled="disabled"
                :editable="editable"
                label="정비시간"
                :range="true"
                :minuteStep="10"
                type="time"
                name="maintenanceTimeList"
                v-model="maintenanceTimeList">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-datepicker
                :disabled="disabled"
                :editable="editable"
                label="운전정지시간"
                :range="true"
                :minuteStep="10"
                type="time"
                name="stopDrivingTimeList"
                v-model="stopDrivingTimeList">
              </c-datepicker>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-field
                :disabled="disabled"
                label="정비부서 입회자"
                name="memDeptEntrantUserId"
                type="user" 
                v-model="result.memDeptEntrantUserId">
              ></c-field>
            </div>
            <!-- <div v-if="editable" class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-moc 
                :editable="editable"
                :disabled="disabled"
                label="MOC 번호"
                name="sopMocId"
                v-model="result.sopMocId">
              </c-moc>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="정비자 자격사항"
                name="maintenanceQualifications"
                v-model="result.maintenanceQualifications">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="정비원인"
                :rows="2"
                name="maintenanceCause"
                v-model="result.maintenanceCause">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="정비내용"
                :rows="2"
                name="maintenanceContent"
                v-model="result.maintenanceContent">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="정비결과요약"
                :rows="2"
                name="maintenanceResultSummary"
                v-model="result.maintenanceResultSummary">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="관리자의 검토의견"
                :rows="2"
                name="reviewOpinionOfMananger"
                v-model="result.reviewOpinionOfMananger">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-maintenance-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        disabled: false,
        noPlan: 'N',
        multiple: false,
        minEquipmentMaintenanceId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentMaintenanceId: '',
        plantCd: '',
        equipmentCd: '',
        maintenanceStatusCd: null,
        equipmentTypeCd: '',
        equipmentCheckTypeCd: null,
        memDeptEntrantUserId: '',
        maintenanceDueDate: '',
        maintenanceDueUserId: '',
        sopMocId: '',
        maintenanceUserId: '',
        maintenanceQualifications: '',
        maintenanceDate: '',
        maintenanceResultSummary: '',
        inspResultAfterMemCd: null,
        maintenanceCompleteFlag: null,
        noPlanResultEnrollFlag: 'N',
        recentMaintainDate: '',
        relatedLawsName: '',
        pmCycleCd: '',
        pmCycleName: '',
        maintenanceCause: '',
        maintenanceContent: '',
        maintenanceStartTime: '',
        maintenanceEndTime: '',
        stopDrivingStartTime: '',
        stopDrivingEndTime: '',
        reviewOpinionOfMananger: '',
      },
      title: '',
      updateMode: false,

      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isSave: false,
      saveUrl: 'transactionConfig.sop.min.maintenance.plan.insert.url',
      saveType: 'POST',

      checkTypeCdItems: [],
      chkResultItems: [],
      chkRsltYnItems: [],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      stopDrivingTimeList: [],
      maintenanceTimeList: [],
    };
  },
  computed: {
    disabled() {
      return this.result.maintenanceStatusCd === 'MCSC000015'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.min.maintenance.plan.get.url;
      this.insertUrl = transactionConfig.sop.min.maintenance.result.insert.url;
      this.updateUrl = transactionConfig.sop.min.maintenance.result.update.url;
      this.completeUrl = transactionConfig.sop.min.maintenance.result.complete.url;
      // url setting
      // code setting
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ]
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.minEquipmentMaintenanceId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.minEquipmentMaintenanceId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.result = _result.data;

          if (_result.data.stopDrivingStartTime && _result.data.stopDrivingEndTime) {
            this.stopDrivingTimeList = [_result.data.stopDrivingStartTime, _result.data.stopDrivingEndTime];
          }
          if (_result.data.maintenanceStartTime && _result.data.maintenanceEndTime) {
            this.maintenanceTimeList = [_result.data.maintenanceStartTime, _result.data.maintenanceEndTime];
          }
          this.updateMode = true;
        },);
      }
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data) {
        this.result.minEquipmentMaintenanceId = '';
        this.result.plantCd = data[0].plantCd;
        this.result.plantName = data[0].plantName;
        this.result.memDeptEntrantUserId = data[0].managementDepts,
        this.result.memDeptEntrantUserName = data[0].managementDeptName; 
        this.result.sopMocId = '';
        this.result.equipmentCheckTypeCd = null;
        this.result.maintenanceStatusCd = 'MCSC000005';
        this.result.maintenanceDueUserName = ''; 
        this.result.maintenanceDueUserId = '';
        this.result.maintenanceDueDate = '';
        this.result.equipmentTypeCd = data[0].equipmentTypeCd; 
        this.result.equipmentTypeName = data[0].equipmentTypeName;
        this.result.equipmentCd = data[0].equipmentCd; 
        this.result.equipmentName = data[0].equipmentName;
        this.result.pmCycleName = data[0].pmCycleName;
        this.result.pmCycleCd = data[0].pmCycleCd;
        this.result.recentMaintainDate = data[0].recentMaintainDate;
        this.result.noPlanResultEnrollFlag = 'N';
        this.result.relatedLawsName = data[0].relatedLawsName;
        this.result.regUserId = this.$store.getters.user.userId;
        this.result.chgUserId = this.$store.getters.user.userId;
        this.result.editFlag = 'C';
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data = this.$_.reject(this.grid.data, { equipmentCd: item.equipmentCd })
        })
      }
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
    callbackProxy(data, props, col) {
      if (col.name === 'chngNum') {
        this.grid.data[props.rowIndex]['chngNum'] = data.chngNum;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveInspection() {
      if (this.popupParam.minEquipmentMaintenanceId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      if (this.popupParam.noPlan === 'Y') {
        this.result.noPlanResultEnrollFlag = 'Y';
      } 
      if (!this.result.equipmentCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }  
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.maintenanceTimeList && this.maintenanceTimeList.length > 0) {
                this.result.maintenanceStartTime = this.maintenanceTimeList[0];
                this.result.maintenanceEndTime = this.maintenanceTimeList[1];
              } 
              if (this.stopDrivingTimeList&& this.stopDrivingTimeList.length > 0) {
                this.result.stopDrivingStartTime = this.stopDrivingTimeList[0];
                this.result.stopDrivingEndTime = this.stopDrivingTimeList[1];
              } 
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.minEquipmentMaintenanceId = result.data
      this.getDetail();
    },
    resultComplete() {
      // if (this.result.maintenanceCompleteFlag === 'N') {
      //     window.getApp.$emit('ALERT', {
      //       title: '안내', // 안내
      //       message: '미완료 된 설비정비입니다.', // 미완료 된 설비정비입니다
      //       type: 'warning', // success / info / warning / error
      //     });
      //     return;
      //   }
        this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '정비완료 하시겠습니까?',
            // TODO : 정비완료 하시겠습니까?
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.result.maintenanceCompleteFlag = 'Y';
              if (this.maintenanceTimeList && this.maintenanceTimeList.length > 0) {
                this.result.maintenanceStartTime = this.maintenanceTimeList[0];
                this.result.maintenanceEndTime = this.maintenanceTimeList[1];
              } 
              if (this.stopDrivingTimeList&& this.stopDrivingTimeList.length > 0) {
                this.result.stopDrivingStartTime = this.stopDrivingTimeList[0];
                this.result.stopDrivingEndTime = this.stopDrivingTimeList[1];
              } 
              this.$http.url = this.completeUrl;
              this.$http.type = 'PUT';
              this.$http.param = this.result;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDetail();
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
          },
        });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
        
    },
    // linkClick() {
    //   this.popupOptions.title = 'MOC 상세'; // 교육과정 상세
    //   this.popupOptions.param = {
    //     type: 'N'
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocDetail.vue'}`);
    //   this.popupOptions.width = '95%';
    //   this.popupOptions.height = '500px';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeLinkPopup;
    // },
    closeLinkPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
